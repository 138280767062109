@use 'node_modules/@sparbanken-syd/sparbanken-syd-theme/theme' as spb;

@include spb.set-typography;

html {
  height: 100%;
  margin: 0;
  width: 100%;
}

body {
  font-family: sans-serif;
  height: 100%;
  margin: 0 auto;
  width: 100%;
}

/* General styling that applies to many pages */

/* Plain is just a holder for content on pages w/o hero images. */
.spb-plain-content {
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  overflow: hidden;
}

/* use this to grow */
.spb-filler {
  display: flex;
  flex: 1 0;
}

textarea {
  font-size: 16px !important;
}
